<template>
    <TableBaseFilterable
        :class="dynamicClasses"
        :headers="headers"
        :items="items"
        :itemsPerPage="10"
        :selected.sync="selected"
        :filter.sync="poLinesFilter"
        :height="contentHeight"
        filterLabel="Filter Purchase Order Lines..."
        showSelect
        hideAdd
    >
        <template #[`item.titleDescription`]="{item}">
            <strong v-if="existingPurchaseOrderItems.includes(item.id)">
                (Associated)
            </strong>
            {{ item.titleDescription }}
        </template>
        <template #[`item.requestedPrice`]="{item}" >
            {{ FormatUtil.toCurrency( (item.requestedPrice) ) }}
        </template>
        <template #[`item.total`]="{item}" >
            {{ FormatUtil.toCurrency( (item.requestedQuantity * item.requestedPrice) ) }}
        </template>
    </TableBaseFilterable>
</template>

<script>
import FormatUtil from '@/utils/FormatUtil';

// Components
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';

export default {
    name: 'TablePurchaseOrderLines',
    components: {
        TableBaseFilterable,
    },
    props: {
        contentHeight: {
            type: String,
            required: false,
            default: '',
        },
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
        existingPurchaseOrderItems: {
            type: Array,
            required: false,
            default: () => [],
        },
        error: {
            type: Boolean,
            required: false,
            default: false,
        },
        value: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            FormatUtil,
            poLinesFilter: '',
        };
    },
    computed: {
        dynamicClasses() {
            const classes = [ 'poLines-card' ];
            classes.push( this.error ? 'error-border' : 'black-border' );
            return classes.join( ' ' );
        },
        headers() {
            return [
                {
                    text: 'EAN',
                    value: 'productCode',
                },
                {
                    text: 'Title',
                    value: 'titleDescription',
                },
                {
                    text: 'Condition',
                    value: 'condition',
                },
                {
                    text: 'Quantity',
                    value: 'requestedQuantity',
                },
                {
                    text: 'Price',
                    value: 'requestedPrice',
                },
                {
                    text: 'Total',
                    value: 'total',
                    sortable: false,
                },
            ];
        },
        selected: {
            get() {
                return this.value;
            },
            set( val ) {
                this.$emit( 'input', val );
            },
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "@/assets/sass/customDatatable.scss";

    .poLines-card {
        &.black-border {
            border: 1px solid rgba(0,0,0,0.38);
            &:hover {
                border: 1px solid rgba(0,0,0,0.87);
            }
        }
        &.error-border {
            color: var(--v-error-base);
            border: 2px solid currentColor;
        }
    }
</style>
