<template>
    <v-autocomplete
        v-model="dropdownPurchaseOrder"
        v-bind="$attrs"
        :loading="inFlight"
        :itemText="dropdownItemText"
        :searchInput.sync="dropdownPurchaseOrderSearch"
        :items="displayedDropdownPurchaseOrders"
        placeholder="Start typing purchase order number"
        outlined
        dense
        hideNoData
        hideDetails
        returnObject
        loaderHeight="0.33rem"
    />
</template>
<script>
// Vuex
import { mapState } from 'vuex';
import { fullNameSpace, actionTypes } from '@/store/modules/Invoice/Detail';

export default {
    name: 'AutocompletePurchaseOrder',
    props: {
        value: {
            type: Object,
            required: true,
        },
        useProvidedItems: {
            type: Boolean,
            required: false,
            default: false,
        },
        dropdownItemText: {
            type: String,
            required: false,
            default: 'number',
        },
        minSearchLength: {
            type: Number,
            required: false,
            default: 4,
        },
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
        preselectedSearch: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            cachedSearch: null,
            dropdownPurchaseOrders: [],
            dropdownPurchaseOrderSearch: '',
        };
    },
    computed: {
        ...mapState( {
            inFlight: ( state ) => state.Invoice.Detail.inFlight,
        } ),
        dropdownPurchaseOrder: {
            get() {
                return this.value;
            },
            set( val ) {
                this.$emit( 'input', val );
            },
        },
        displayedDropdownPurchaseOrders() {
            const purchaseOrdersToDisplay = this.useProvidedItems
                ? this.items
                : this.dropdownPurchaseOrders.filter( ( purchaseOrder ) => purchaseOrder.purchaseOrderItemDTOList.length > 0 );
            // Invoices are only created from these three status
            return purchaseOrdersToDisplay.map( ( purchaseOrder ) => ( {
                ...purchaseOrder,
                numberWithStatus: `${ purchaseOrder.number } - ${ purchaseOrder.status }`,
            } ) );
        },
    },
    watch: {
        async dropdownPurchaseOrderSearch( val ) {
            await this.getDropdownPurchaseOrders( val );
        },
    },
    async mounted() {
        if ( this.preselectedSearch === '' ) {
            return;
        }
        await this.getDropdownPurchaseOrders( this.preselectedSearch );
        const matchingPurchaseOrders = this.displayedDropdownPurchaseOrders
            .filter( ( purchaseOrder ) => purchaseOrder[ this.dropdownItemText ].includes( this.preselectedSearch ) );
        this.dropdownPurchaseOrder = matchingPurchaseOrders.length > 0
            ? matchingPurchaseOrders[ 0 ]
            : {};
    },
    methods: {
        async getDropdownPurchaseOrders( val ) {
            const searchable = val && val.length >= this.minSearchLength;
            const newSearch = val && !val.includes( this.cachedSearch );
            if ( searchable && newSearch && !this.useProvidedItems ) {
                this.cachedSearch = val;
                this.dropdownPurchaseOrders = await this.$store.dispatch(
                    `${ fullNameSpace }${ actionTypes.searchForPurchaseOrders }`,
                    { purchaseOrderNumber: val },
                );
            }
            if ( !this.dropdownPurchaseOrders ) {
                this.dropdownPurchaseOrders = [];
            }
        },
    },
};
</script>
