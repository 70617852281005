<template>
    <TableBaseFilterable
        :headers="headers"
        :items="info.associatedPurchaseOrders"
        :filter.sync="purchaseOrderFilter"
        :loading="inFlight || loadingPOSearch"
        filterLabel="Filter Purchase Orders..."
        hideAdd
        @click:row="(e, { item }) => openPurchaseOrderDetailPage(item)"
    >
        <template #[`item.totalLeftToReconcile`]="{ item }">
            {{ FormatUtil.toCurrency( item.totalLeftToReconcile) }}
        </template>
    </TableBaseFilterable>
</template>

<script>
// Vuex
import { mapState, mapActions } from 'vuex';

// Components
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';

// Utils
import FormatUtil from '@/utils/FormatUtil';

export default {
    name: 'AssociatedPurchaseOrders',
    components: {
        TableBaseFilterable,
    },
    data() {
        return {
            loadingPOSearch: false,
            FormatUtil,
            headers: [
                {
                    text: 'Purchase Order Number',
                    align: 'start',
                    value: 'poNumber',
                },
                {
                    text: 'Total Ordered',
                    align: 'end',
                    value: 'totalOrdered',
                },
                {
                    text: 'Total Received',
                    align: 'end',
                    value: 'totalReceived',
                },
                {
                    text: 'Total Invoiced',
                    align: 'end',
                    value: 'totalInvoiced',
                },
                {
                    text: 'Total Discrepancy',
                    align: 'end',
                    value: 'totalDiscrepancy',
                },
                {
                    text: 'Total Left to Reconcile',
                    align: 'end',
                    value: 'totalLeftToReconcile',
                },
            ],
            purchaseOrderFilter: '',
        };
    },
    computed: {
        ...mapState( {
            info: ( state ) => state.Invoice.Detail.info,
            inFlight: ( state ) => state.Invoice.Detail.inFlight,
        } ),
    },
    methods: {
        ...mapActions( {
            getByFilters: 'PurchaseOrder/getByFilters',
        } ),
        async openPurchaseOrderDetailPage( { poNumber } ) {
            this.loadingPOSearch = true;
            await this.getByFilters( {
                purchaseOrderNumber: poNumber,
            } );
            this.$router.push( `/search/${ poNumber }` );
        },
    },
};
</script>
