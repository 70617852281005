<template>
    <div>
        <v-row noGutters>
            <v-spacer />
            <v-dialog
                v-model="showAddPOLineDialog"
                class="dialog"
            >
                <v-card>
                    <v-card-title class="white--text primary dialog__header">
                        <h3>Add Purchase Order Lines</h3>
                        <v-btn
                            icon
                            @click="showAddPOLineDialog = false"
                        >
                            <v-icon color="white">mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <div class="dialog__content">
                        <div>
                            Select Purchase Order
                        </div>
                        <v-row noGutters>
                            <v-col
                                cols="8"
                                class="dialog__content__filter"
                            >
                                <AutocompletePurchaseOrder
                                    v-model="dropdownPurchaseOrder"
                                    :useProvidedItems="!includeAllPurchaseOrders"
                                    :items="info.poItems"
                                    :dropdownItemText="dropdownItemText()"
                                    @input="setCurrentPurchaseOrderLines"
                                />
                            </v-col>
                            <v-col
                                cols="4"
                                class="dialog__content__filter dialog__content__filter--toggle"
                            >
                                <v-switch
                                    v-model="includeAllPurchaseOrders"
                                    hideDetails
                                    inset
                                />
                                Include all purchase orders.
                            </v-col>
                        </v-row>
                        <div class="purchase-order-lines-table">
                            <div class="purchase-order-lines-table__header">
                                Select Purchase Order Lines
                            </div>
                            <TablePurchaseOrderLines
                                v-model="selectedPurchaseOrderLines"
                                contentHeight="30vh"
                                :items="currentPurchaseOrderLines"
                                :existingPurchaseOrderItems="poItemIds"
                            />
                        </div>
                    </div>
                    <v-card-actions class="dialog__actions">
                        <v-spacer/>
                        <v-btn
                            outlined
                            @click="showAddPOLineDialog = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            color="primary"
                            @click="addSelectedPOLinesToInvoice()"
                        >
                            Select
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row
            noGutters
            class="unreconciled-purchase-orders-table"
        >
            <TableBaseFilterable
                :items="displayedTableItems"
                :headers="headers"
                :loading="inFlight"
                :filter.sync="purchaseOrderFilter"
                addLabel="Add PO Lines"
                filterLabel="Filter Purchase Order Lines..."
                class="purchase-order-table"
                @activator:click="showAddPOLineDialog = true"
            >
                <template #[`item.invoiceQty`]="{item}">
                    <v-text-field
                        v-for="( invoiceItem, invoiceIndex ) in item.invoiceItems"
                        :key="invoiceItem.invoiceItemId"
                        :value="invoiceItem.qty"
                        dense
                        hideDetails
                        outlined
                        class="table-quantity-field"
                        :rules="[ latestQtyRule( item ) ]"
                        @input="( value ) => updateQty(item.poItemId, invoiceIndex, value )"
                        @blur="updateInfo()"
                    />
                    <span
                        v-if="item.invoiceItems.length > 1"
                        class="table-quantity-field"
                    >
                        Total: {{ totalInvoiceQty( item ) }}
                    </span>
                </template>
                <template #[`item.invoicePrice`]="{item}">
                    <InputCurrency
                        v-for="( invoiceItem, invoiceIndex ) in item.invoiceItems"
                        :key="invoiceItem.invoiceItemId"
                        :value="invoiceItem.price"
                        @input="( value ) => updatePrice( item.poItemId, invoiceIndex, value )"
                        @blur="updateInfo()"
                    />
                </template>
                <template #[`item.requestQty`]="{ item }">
                    <div
                        :class="latestQtyRule( item ) ? '': 'error--text'"
                    >
                        {{ item.requestQty }}
                    </div>
                </template>
                <template #[`item.latestTotal`]="{ item }">
                    {{ totalInvoicePrice( item ) }}
                </template>
                <template #[`item.qtyRecv`]="{ item }">
                    {{ qtyRecv( item ) }}
                </template>
                <template #[`item.qtyDisc`]="{ item }">
                    {{ qtyDisc( item ) }}
                </template>
                <template #[`item.invDisc`]="{ item }">
                    {{ invDisc( item ) }}
                </template>
                <template #[`item.responsePrice`]="{ item }">
                    {{ responsePrice( item )  }}
                </template>
                <template #[`item.lineCost`]="{ item }">
                    {{ lineCost( item ) }}
                </template>
                <template #[`item.lineRecl`]="{ item }">
                    {{ lineRecl( item ) }}
                </template>
                <template #[`item.remove`]="{ item }">
                    <v-btn
                        :disabled="inFlight"
                        icon
                        aria-label="delete"
                        @click="removePOItem(Number(item.poItemId))"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
            </TableBaseFilterable>
        </v-row>
    </div>
</template>

<script>
// Vuex
import { mapState } from 'vuex';

// Utils
import ObjectUtil from '@/utils/ObjectUtil';
import FormatUtil from '@/utils/FormatUtil';

// Components
import AutocompletePurchaseOrder from '@/components/dropdowns/AutocompletePurchaseOrder';
import InputCurrency from '@/components/base/inputs/InputCurrency';
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';
import TablePurchaseOrderLines from '../shared/TablePurchaseOrderLines';

export default {
    name: 'UnreconciledPurchaseOrders',
    components: {
        TablePurchaseOrderLines,
        TableBaseFilterable,
        AutocompletePurchaseOrder,
        InputCurrency,
    },
    props: {
        isDirty: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            showAddPOLineDialog: false,
            selectedPurchaseOrderLines: [],
            dropdownPurchaseOrder: {},
            includeAllPurchaseOrders: true,
            currentPurchaseOrderLines: [],
            purchaseOrderFilter: '',
            headers: [
                {
                    text: 'EAN',
                    align: 'start',
                    value: 'productInfo.ean',
                },
                {
                    text: 'Title - Author',
                    value: 'productInfo.description',
                },
                {
                    text: 'PO Number',
                    value: 'poNumber',
                },
                {
                    text: 'Cond',
                    value: 'condition',
                },
                {
                    text: 'Inv Qty',
                    class: 'grouped-heading',
                    cellClass: 'grouped-cell',
                    value: 'invoiceQty',
                },
                {
                    text: 'Inv Price',
                    class: 'grouped-heading',
                    cellClass: 'grouped-cell',
                    value: 'invoicePrice',
                },
                {
                    text: 'Inv Line',
                    align: 'end',
                    class: 'grouped-heading',
                    cellClass: 'grouped-cell',
                    value: 'latestTotal',
                    sortable: false,
                },
                {
                    text: 'Qty Ord',
                    align: 'end',
                    value: 'requestQty',
                },
                {
                    text: 'Qty Recv',
                    align: 'end',
                    value: 'qtyRecv',
                },
                {
                    text: 'Qty Disc',
                    align: 'end',
                    value: 'qtyDisc',
                    sortable: false,
                },
                {
                    text: 'Inv Disc',
                    align: 'end',
                    class: 'grouped-heading',
                    cellClass: 'grouped-cell',
                    value: 'invDisc',
                    sortable: false,
                },
                {
                    text: 'Unit Cost',
                    align: 'end',
                    class: 'grouped-heading',
                    cellClass: 'grouped-cell',
                    value: 'responsePrice',
                },
                {
                    text: 'Line Cost',
                    align: 'end',
                    class: 'grouped-heading',
                    cellClass: 'grouped-cell',
                    value: 'lineCost',
                    sortable: false,
                },
                {
                    // Unit cost multiplied by quantity unreconciled
                    text: 'Line Recl',
                    align: 'end',
                    class: 'grouped-heading',
                    cellClass: 'grouped-cell',
                    value: 'lineRecl',
                    sortable: false,
                },
                {
                    text: '',
                    value: 'remove',
                },
            ],
        };
    },
    computed: {
        ...mapState( {
            info: ( state ) => state.Invoice.Detail.info,
            inFlight: ( state ) => state.Invoice.Detail.inFlight,
        } ),
        /** @returns { String[] } */
        poItemIds() {
            return this.poItems.filter( ( item ) => item.invoiceItems.length > 0 ).map( ( item ) => item.poItemId );
        },
        poItems: {
            get() {
                return this.info.poItems;
            },
            set( value ) {
                this.dirty = true;
                this.$store.commit( 'Invoice/Detail/SET_PO_ITEMS', { value } );
            },
        },
        /** @returns { item[] } */
        displayedTableItems() {
            return this.poItems.filter( ( item ) => (
                item.invoiceItems
                && item.invoiceItems[ 0 ]
                && ObjectUtil.isObject( item.invoiceItems[ 0 ] )
                && ObjectUtil.isNotEmpty( item.invoiceItems[ 0 ] )
            ) );
        },
        dirty: {
            get() {
                return this.isDirty;
            },
            set( value ) {
                this.$emit( 'update:isDirty', value );
            },
        },
    },
    methods: {
        async addSelectedPOLinesToInvoice() {
            this.showAddPOLineDialog = false;
            const toAdd = this.selectedPurchaseOrderLines.map(
                ( line ) => ( {
                    ...line,
                    poItemId: line.id,
                    invoiceItems: [ {} ],
                } ),
            );
            toAdd.push( ...this.info.poItems );
            await this.updateUnreconciledPurchaseOrderLines( toAdd, true );
            this.selectedPurchaseOrderLines = [];
        },
        async updateUnreconciledPurchaseOrderLines( items, commitItems = false ) {
            this.dirty = true;
            this.poItems = items;
            try {
                const { poItems } = await this.$store.dispatch( 'Invoice/Detail/update' );
                if ( commitItems ) {
                    this.poItems = poItems;
                }
                this.dirty = false;
            } catch ( e ) {
                throw new Error( e );
            }
        },
        async setCurrentPurchaseOrderLines() {
            let purchaseOrder = this.dropdownPurchaseOrder;
            if ( !this.includeAllPurchaseOrders ) {
                purchaseOrder = await this.$store.dispatch(
                    'Invoice/Detail/searchForSinglePurchaseOrder',
                    { purchaseOrderNumber: this.dropdownPurchaseOrder.poNumber },
                );
            }
            this.currentPurchaseOrderLines = purchaseOrder.purchaseOrderItemDTOList ? purchaseOrder.purchaseOrderItemDTOList : [];
        },
        /** @returns { String } */
        dropdownItemText() {
            return this.includeAllPurchaseOrders ? 'number' : 'poNumber';
        },
        /** @returns { Number } */
        totalInvoiceQty( item ) {
            return item.invoiceItems.reduce( ( acc, invoiceItem ) => acc + invoiceItem.qty, 0 ) ?? 0;
        },
        /** @returns { String } */
        totalInvoicePrice( item ) {
            return FormatUtil.toCurrency(
                item.invoiceItems.reduce( ( acc, invoiceItem ) => acc + invoiceItem.price * Number.parseFloat( invoiceItem.qty ), 0 ),
            );
        },
        openPurchaseOrderDetailPage( id ) {
            this.$router.push( `/search/${ id }` );
        },
        /**
         * Highlights field when true
         * @returns { Boolean }
         */
        latestQtyRule( item ) {
            return this.totalInvoiceQty( item ) <= item.requestQty;
        },
        /**
         * Discrepancy between requested and received
         * @returns { Number }
         */
        qtyDisc( item ) {
            return item.requestQty - this.qtyRecv( item );
        },
        /**
         * Total Items Received
         * @returns { Number }
         */
        qtyRecv( { receivedItems, damagedReceivedItems } ) {
            const receivedTotal = receivedItems.reduce( ( prev, curr ) => prev + curr.qty, 0 );
            const damagedReceivedTotal = damagedReceivedItems.reduce( ( prev, curr ) => prev + curr.qty, 0 );
            return receivedTotal + damagedReceivedTotal;
        },
        // Discrepancy between invoiced and received
        invDisc( item ) {
            return this.qtyRecv( item ) - this.totalInvoiceQty( item );
        },
        /**
         * Just adds some decimal places
         * @returns { String }
         */
        responsePrice( item ) {
            return FormatUtil.toCurrency( ( this.totalInvoicePrice( item ) / this.totalInvoiceQty( item ) ) );
        },
        /**
         * Calculates cost of received items
         * @returns { String }
         */
        lineCost( item ) {
            const responsePrice = Object.keys( item ).includes( 'responsePrice' ) ? item.responsePrice : 0;
            return FormatUtil.toCurrency( ( this.qtyRecv( item ) * responsePrice ) );
        },
        /**
         * Discrepancy between the cost of received items and the total invoiced
         * @returns { String }
         */
        lineRecl( item ) {
            const lineCost = this.lineCost( item );
            const latestTotal = this.totalInvoicePrice( item );
            if ( lineCost && latestTotal ) {
                return FormatUtil.toCurrency( ( Number.parseFloat( lineCost ) - Number.parseFloat( latestTotal ) ) );
            }
            return '';
        },
        async updateQty( itemId, invoiceIndex, qty ) {
            this.dirty = true;
            this.$store.commit( 'Invoice/Detail/SET_PO_ITEMS_INVOICE_ITEM_QTY', {
                itemId,
                invoiceIndex,
                qty: +qty,
            } );
        },
        async updatePrice( itemId, invoiceIndex, price ) {
            this.dirty = true;
            this.$store.commit( 'Invoice/Detail/SET_PO_ITEMS_INVOICE_ITEM_PRICE', {
                itemId,
                invoiceIndex,
                price: +price,
            } );
        },
        async removePOItem( itemId ) {
            this.dirty = true;
            this.$store.commit( 'Invoice/Detail/REMOVE_PO_ITEM_FROM_PO_ITEMS', {
                itemId,
            } );
            // https://stackoverflow.com/a/66021166 The emitted dirty flag hasn't been set until next tick
            this.$nextTick( async () => {
                await this.updateInfo();
            } );
        },
        async updateInfo() {
            try {
                if ( this.dirty ) {
                    await this.$store.dispatch( 'Invoice/Detail/update' );
                    this.dirty = false;
                }
            } catch ( error ) {
                this.showUpdateErrorModal = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .dialog {
        #app &__header {
            padding:1.75rem;
            display:flex;
            justify-content: space-between;
        }
        &__content {
            margin: 2rem;

            &__filter {
                display: flex;
                align-items: flex-start;
                .row &--toggle {
                    margin-top: 0.5rem;
                    padding-left: 2rem;

                    .v-input--selection-controls {
                        margin-top: 0;
                        padding-top: 0;
                    }
                }
            }
        }
        &__actions.v-card__actions {
            display: flex;
            justify-content: right;
            padding: 2rem;
            padding-top: 0;
        }
    }
    .purchase-order-table {
        margin-top: 1rem;
    }
</style>
