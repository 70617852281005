<template>
    <v-dialog v-model="showCompleteModal">
        <v-card>
            <v-card-title class="primary white--text dialog__header">
                <h3>Complete Purchase Orders</h3>
                <v-spacer />
                <v-btn
                    icon
                    @click="showCompleteModal = false"
                >
                    <v-icon color="white">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <TableBaseFilterable
                :loading="associatedInFlight"
                :items="associatedPurchaseOrders"
                :headers="headers"
                :selected.sync="selectedPurchaseOrders"
                :filter.sync="filter"
                filterLabel="Filter Purchase Orders..."
                showSelect
                hideAdd
                class="dialog__content"
            />
            <v-card-actions class="dialog__actions" >
                <v-spacer/>
                <v-btn
                    outlined
                    color="primary"
                    @click="cancelSelection()"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    @click="completeSelectedPurchaseOrders()"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// Vuex
import { mapState, mapActions } from 'vuex';

// Components
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';

export default {
    name: 'ModalPurchaseOrderCompletion',
    components: {
        TableBaseFilterable,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        associatedPurchaseOrders: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            headers: [
                {
                    text: 'PO Number',
                    value: 'number',
                },
                {
                    text: 'Location',
                    value: 'location.name',
                },
                {
                    text: 'Supplier',
                    value: 'supplier.name',
                },
                {
                    text: 'Units Ordered',
                    align: 'end',
                    value: 'numberOfUnits',
                },
                {
                    text: 'Number of EANs',
                    align: 'end',
                    value: 'numberOfISBNs',
                },
                {
                    text: 'Create Date',
                    value: 'createDate',
                },
                {
                    text: 'Status',
                    value: 'status',
                },
            ],
            selectedPurchaseOrders: [],
            filter: '',
        };
    },
    computed: {
        ...mapState( {
            associatedInFlight: ( state ) => state.Invoice.Detail.associatedPurchaseOrdersInFlight,
        } ),
        showCompleteModal: {
            get() {
                return this.value;
            },
            set( val ) {
                this.$emit( 'input', val );
            },
        },
    },
    methods: {
        ...mapActions( {
            completeSelectedAssociatedPurchaseOrders: 'Invoice/Detail/completeSelectedAssociatedPurchaseOrders',
        } ),
        async completeSelectedPurchaseOrders() {
            if ( this.selectedPurchaseOrders && this.selectedPurchaseOrders.length > 0 ) {
                await this.completeSelectedAssociatedPurchaseOrders( { selectedAssociatedPurchaseOrders: this.selectedPurchaseOrders } );
                // Clear the table so we don't see incorrect info in the table
                this.$emit( 'clear' );
            }
            this.showCompleteModal = false;
            await this.goToPOSearch();
        },
        async goToPOSearch() {
            // Can't use route.path because search route.path contains dynamic id instead of query param.
            await this.$router.push( '/search' );
        },
        async cancelSelection() {
            this.showCompleteModal = false;
            await this.goToPOSearch();
        },
    },
};
</script>

<style lang="scss" scoped>
    .dialog {
        #app &__header {
            padding: 1.5rem 2rem;
            display:flex;
            justify-content: space-between;
        }
        &__content {
            margin: 2rem;
            border: 1px solid rgba(0,0,0,0.38);
            &:hover {
                border: 1px solid rgba(0,0,0,0.87);
            }
        }
        &__actions.v-card__actions {
            display: flex;
            justify-content: right;
            padding: 2rem;
            padding-top: 0;
        }
    }
</style>
