<template>
    <v-dialog
        v-model="model"
        class="modal-update-error"
        maxWidth="300"
        persistent
    >
        <v-card>
            <v-card-title class="headline">
                Uh oh...
            </v-card-title>
            <v-card-text class="card-text">
                {{ defaultErrorMessage }} {{ errorMessage }}
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    @click="cancelButtonHandler()"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    :disabled="inFlight"
                    :loading="inFlight"
                    @click="tryAgainButtonHandler()"
                >
                    Try Again
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
/**
 * Use v-model with this component
 * @emits 'input'
 */
export default {
    name: 'ModalUpdateError',
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        updateActionPath: {
            type: String,
            required: true,
        },
        defaultErrorMessage: {
            type: String,
            required: false,
            default: 'Error updating.',
        },
        inFlight: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            errorMessage: '',
        };
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set( value ) {
                this.$emit( 'input', value );
            },
        },
    },
    methods: {
        /**
         * @emits 'input'
         */
        cancelButtonHandler() {
            this.errorMessage = '';
            this.$emit( 'input', false );
        },
        async tryAgainButtonHandler() {
            try {
                await this.$store.dispatch( this.updateActionPath );
                this.cancelButtonHandler();
            } catch ( error ) {
                this.errorMessage = error.message;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/sass/variables.scss';
.v-dialog > .v-card > .v-card__text {
    margin-top: 1rem;
}

.headline {
    background-color: var(--v-warning-base);
    color: $white;
}
</style>
