<template>
    <v-form
        class="sidebar-container"
        @submit.prevent="postFiltersToGetInvoices()"
    >
        <div class='logistics-section sidebar-container__section'>
            <!-- Primary Supplier Selection -->
            <div>
                <h3 class="sidebar-container__header">Location</h3>
                <span class="logistics-section__input">
                    <DropdownLocationList
                        v-model="location"
                        :prependAll="true"
                        outlined
                    />
                </span>
            </div>
            <div>
                <h3 class="sidebar-container__header">Supplier</h3>
                <span class="logistics-section__input">
                    <DropdownPrimarySupplier
                        v-model="primarySupplier"
                        outlined
                        :hideDetails="true"
                    />
                </span>
            </div>
            <div>
                <h3 class="sidebar-container__header">Invoice Number</h3>
                <v-text-field
                    v-model="supplierDocumentNumber"
                    backgroundColor="white"
                    dense
                    hideDetails
                    outlined
                />
            </div>
        </div>

        <!-- Invoice Date -->
        <div class="search-parameter sidebar-container__section">
            <h3 class="sidebar-container__header">Invoice Date</h3>
            <div class='search-parameter__row'>
                <InputDateRange
                    :start-date.sync="dateStart"
                    :end-date.sync="dateEnd"
                    label="Invoice Date"
                    outlined
                />
            </div>
        </div>

        <!-- PO Number Quick Search -->
        <div class="sidebar-container__section">
            <h3 class="sidebar-container__header">Purchase Order Number</h3>
            <v-text-field
                v-model="purchaseOrderNumber"
                clearable
                dense
                hideDetails
                type="text"
                backgroundColor="white"
                outlined
            />
        </div>
        <!-- PO Number Quick Search -->
        <div class="sidebar-container__section">
            <span class="sidebar-container__header">
                <h3>EAN</h3>
                <ToolTip class="tooltip">
                    <v-icon slot="target" class="tooltip__icon">mdi-information-outline</v-icon>
                    <div slot="content">
                        May increase search time
                    </div>
                </ToolTip>
            </span>
            <v-text-field
                v-model="isbn"
                clearable
                dense
                hideDetails
                type="text"
                backgroundColor="white"
                outlined
                :rules="[rules.isbn]"
            />
        </div>

        <!-- Status -->
        <div
            id="status"
            class="search-parameter sidebar-container__section"
        >
            <h3 class="sidebar-container__header">Status:</h3>
            <CheckboxMultiSelectPersistable
                v-model="statusList"
                :leftColumnKeys="['Pending', 'Problem']"
            />
        </div>

        <!-- Action Buttons -->
        <div
            id="button-container"
            class="sidebar-container__section"
        >
            <v-btn
                outlined
                color="white"
                @click="clearFilters()"
            >
                Clear
            </v-btn>

            <v-btn
                class="button-search"
                type="submit"
            >
                Search
            </v-btn>
        </div>
    </v-form>
</template>

<script>
// Vuex
import { mapGetters, mapState } from 'vuex';
import { fullNameSpace, actionTypes } from '@/store/modules/Invoice';

// Utils
import ValidationUtil from '@/utils/ValidationUtil';

// Components
import DropdownPrimarySupplier from '@/components/dropdowns/DropdownPrimarySupplier';
import DropdownLocationList from '@/views/common/dropdowns/DropdownLocationList';
import CheckboxMultiSelectPersistable from '@/components/base/multiselect/CheckboxMultiSelectPersistable';
import ToolTip from '@/components/base/tooltip/ToolTip';
import InputDateRange from '@/components/InputDateRange';

export default {
    name: 'SidebarFilterInvoices',
    components: {
        CheckboxMultiSelectPersistable,
        DropdownPrimarySupplier,
        DropdownLocationList,
        ToolTip,
        InputDateRange,
    },
    data() {
        return {
            rules: {
                // Returns true if value is falsey OR passes ValidationUtil.isValidIsbn()
                isbn: ( value ) => ( !value || ValidationUtil.isValidIsbn( value ) ),
            },
        };
    },
    computed: {
        ...mapGetters( {
            hasPrimarySuppliers: 'GlobalResources/hasPrimarySupplierList',
            hasFilters: 'Invoice/hasFilters',
        } ),
        ...mapState( {
            invoiceStatusList: ( state ) => state.Invoice.invoiceStatusList,
            invoiceList: ( state ) => state.Invoice.list,
            filters: ( state ) => state.Invoice.filters,
            locationList: ( state ) => state.GlobalResources.locationList,
            primarySupplierList: ( state ) => state.GlobalResources.primarySupplierList,
        } ),
        purchaseOrderNumber: {
            get() {
                return this.filters.purchaseOrderNumber;
            },
            set( value ) {
                this.$store.dispatch( 'Invoice/setFilterPurchaseOrderNumber', { purchaseOrderNumber: value } );
            },
        },
        supplierDocumentNumber: {
            get() {
                return this.filters.supplierDocumentNumber;
            },
            set( value ) {
                this.$store.dispatch( 'Invoice/setFilterSupplierDocumentNumber', { supplierDocumentNumber: value } );
            },
        },
        dateStart: {
            get() {
                return this.filters.dateStart;
            },
            set( value ) {
                this.$store.dispatch( 'Invoice/setFilterDateStart', { dateStart: value } );
            },
        },
        dateEnd: {
            get() {
                return this.filters.dateEnd;
            },
            set( value ) {
                this.$store.dispatch( 'Invoice/setFilterDateEnd', { dateEnd: value } );
            },
        },
        isbn: {
            get() {
                return this.filters.isbn;
            },
            set( value ) {
                this.$store.dispatch( 'Invoice/setFilterISBN', { isbn: value } );
            },
        },
        primarySupplier: {
            get() {
                return this.filters.primarySupplier;
            },
            set( value ) {
                this.$store.dispatch( 'Invoice/setFilterPrimarySupplier', { primarySupplier: value } );
            },
        },
        location: {
            get() {
                return this.filters.location;
            },
            set( value ) {
                this.$store.dispatch( 'Invoice/setFilterLocation', { location: value } );
            },
        },
        statusList: {
            get() {
                return this.filters.statusList;
            },
            set( value ) {
                this.$store.dispatch( 'Invoice/setFilterStatusList', { statusList: value } );
            },
        },
    },
    async beforeMount() {
        await this.$store.dispatch( 'GlobalResources/init' );
        if ( this.$router.currentRoute.query ) {
            const { invoiceNumber, locationId, supplierId } = this.$router.currentRoute.query;
            this.supplierDocumentNumber = invoiceNumber ?? this.supplierDocumentNumber;
            if ( locationId ) {
                this.location = this.locationList.find( ( location ) => ( location.id ).toString() === locationId ) ?? this.location;
            }
            if ( supplierId ) {
                this.primarySupplier = this.primarySupplierList.find( ( supplier ) => ( supplier.id ).toString() === supplierId ) ?? this.primarySupplier;
            }
        }
        if ( this.hasFilters ) {
            this.$store.dispatch( `${ fullNameSpace }${ actionTypes.getByFilters }` );
        }
    },
    methods: {
        clearFilters() {
            this.$store.dispatch( 'Invoice/reset' );
        },
        postFiltersToGetInvoices() {
            this.$store.dispatch( 'Invoice/getByFilters' );
        },
    },
};
</script>

<style scoped lang="scss">
    .sidebar-container {
        ::v-deep .v-input .v-input__control {
            border-radius: 4px;
        }

        ::v-deep .tooltip__icon {
            color: white;
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .two-columns {
        display:grid;
        align-items: center;
        grid-template-columns: 25% auto;
    }

    .logistics-section {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        &__input {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
        >:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    #status h3 {
        align-self: start;
    }

    ::v-deep.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
        padding-left: 0.5rem;
    }

    #filter {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
    }

    #button-container {
        display: flex;
        justify-content: space-around;
        border-bottom: none;

        .button-search {
            color: var(--v-primary-base);
        }
    }

    .search-parameter {
        &__row {
            display: flex;
            align-items: center;
            .v-input {
                margin-top: 0;
                width: 165px;
            }
            p {
                margin: 0 0.5rem 0;
            }
        }
    }
</style>
