<template>
    <div class="sidebar-full custom-container">
        <TheSidebar fullSize>
            <SidebarFilterInvoices />
        </TheSidebar>
        <ThePageHeadingBar
            :pageHeading="$route.meta.title"
            :fullSize="true"
        />
        <section class="main">
            <TableBaseFilterable
                cardElevation="3"
                filterLabel="Filter Invoices..."
                addLabel="Add Invoice"
                :items="invoiceResultList"
                :headers="invoiceHeaders"
                :loading="loading"
                :filter.sync="invoiceFilter"
                sortBy="formattedDate"
                @click:row="(e, { item }) => openInvoiceDetailPage(item)"
                @activator:click="openInvoiceCreate()"
            >
                <template #[`item.poNumbers`]="{ item }">
                    <TextareaReadOnly
                        :content="item.poNumbers.join('\r\n')"
                        :rows="2"
                    />
                </template>
                <template #[`item.isbns`]="{ item }">
                    <TextareaReadOnly
                        :content="item.isbns.join('\r\n')"
                        :rows="2"
                    />
                </template>
                <template #[`item.total`]="{ item }">
                    {{ '$' + item.total.toFixed(2) }}
                </template>
            </TableBaseFilterable>
        </section>
    </div>
</template>

<script>
// Vuex
import { mapState } from 'vuex';

// Utils
import DateUtil from '@/utils/DateUtil';

// Components
import TheSidebar from '@/views/common/TheSidebar';
import ThePageHeadingBar from '@/components/ThePageHeadingBar';
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';
import TextareaReadOnly from '@/components/textareas/TextareaReadOnly';
// Routes
import InvoiceRoute from '@/router/routes/InvoiceReconciliation';
import SidebarFilterInvoices from './search/SidebarFilterInvoices';

export default {
    name: 'InvoiceSearch',
    components: {
        TheSidebar,
        ThePageHeadingBar,
        SidebarFilterInvoices,
        TableBaseFilterable,
        TextareaReadOnly,
    },
    computed: {
        ...mapState( {
            invoiceResultList: ( state ) => state.Invoice.list,
            loading: ( state ) => state.Invoice.inFlight,
            tableFilter: ( state ) => state.Invoice.tableFilter,
        } ),
        invoiceFilter: {
            get() {
                return this.tableFilter;
            },
            set( val ) {
                this.$store.dispatch( 'Invoice/setTableFilter', { tableFilter: val } );
            },
        },
        invoiceHeaders() {
            return [
                {
                    text: 'Invoice Number',
                    align: 'start',
                    value: 'supplierDocumentNumber',
                },
                {
                    text: 'Supplier',
                    value: 'supplier',
                },
                {
                    text: 'PO Number(s)',
                    value: 'poNumbers',
                    sortable: false,
                },
                {
                    text: 'EAN(s)',
                    value: 'isbns',
                    sortable: false,
                },
                {
                    text: 'Invoice Date',
                    value: 'formattedDate',
                    sort: ( a, b ) => DateUtil.sortDays( a, b ),
                },
                {
                    text: 'Status',
                    value: 'invoiceStatus',
                },
                {
                    text: 'Total',
                    value: 'total',
                },
            ];
        },
    },
    methods: {
        openInvoiceCreate() {
            this.$router.push( `${ InvoiceRoute.path }/create` );
        },
        openInvoiceDetailPage( { id } ) {
            this.$router.push( `${ InvoiceRoute.path }/${ id }` );
        },
    },
};
</script>

<style lang="scss" scoped>
main {
    grid-area: main;
}
</style>
